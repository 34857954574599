import * as React from 'react';
import { Box, Paragraph, Button, Drop, Anchor, List, Grid, Card, CardBody, ResponsiveContext, Layer } from 'grommet';
import { DocumentPdf, Linkedin, Gremlin, Github, Upgrade, Reactjs, Code, Anchor as AnchorIcon, Shield, Grommet as GrommetIcon, Test, Inbox, Amazon, Secure, Wordpress, Action, Cubes, Robot, Heroku, Optimize } from 'grommet-icons';
import { Link } from 'react-router-dom';
import { Heading } from 'grommet';
import Prism from 'prismjs';
import OutsideClickHandler from 'react-outside-click-handler';

import { useSelector, useDispatch } from "react-redux"
import { getPages, setActivePageId } from '../../redux/pages/actions';
import { AppState } from '../../redux/pages/interfaces';
import { useRef, useContext, useEffect } from 'react';
import { themeColors } from '../../utilities/themeColors';
import { Header } from '../../partials/Header';

export const EvTheDev: React.FC = (props: any): JSX.Element => {

	const pages: any = useSelector((state: AppState) => state.pages);
	const targetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const dispatch = useDispatch();
	
	const [isSocialMenuShown, setisSocialMenuShown] = React.useState<boolean>(false);
	let [currentStep, setCurrentStep] = React.useState<number>(1);

	const contributions = [
		'Overall app architecture',
		'ReactJs implementation',
		'Laravel implementation',
		'Set up of CI/CD on AWS architecture',
		'Oauth2 server-to-server integration',
		'Dev team leadership',
		'Built bespoke Wordpress plugin',
		'System integrations'
	]
	const roles = [
		'Developer'
	]

	const technologies = [
		{
			name: 'ReactJs',
			component: <Reactjs size='50px'/>,
			description: 'Client-side app',
		},
		{
			name: 'Typescript',
			component: <Shield size='50px' />,
			description: 'For typesafety',
		},
		{
			name: 'NestJs',
			component: <Code size='50px'/>,
			description: 'Backend',
		},
		{
			name: 'React Hooks',
			component: <AnchorIcon size='50px' />,
			description: 'Class-based components is history',
		},
		{
			name: 'Redux Thunk',
			component: <Code size='50px'/>,
			description: 'State and side-effects management',
		},
		{
			name: 'Functional',
			component: <Optimize size='50px' />,
			description: '.. Programming, to keep components testable and small',
		},
		{
			name: 'Jest',
			component: <Test size='50px' />,
			description: 'For TDD approach',
		},
		{
			name: 'Heroku',
			component: <Heroku size='50px' />,
			description: 'For hosting',
		},
		{
			name: 'Grommet',
			component: <GrommetIcon size='50px' />,
			description: 'UI Templating',
		}
	]
	
	useEffect(() => {
      	// dispatch(getPages());
		dispatch(setActivePageId('4'))
	}, []);

	return (
		
		<Box
			fill={true}
			align='center'
			justify='between'
		>
			<Header />
			
			<Box
				direction='column'
				align='center'
				justify='center'
				fill={true}
				style={{ maxWidth: '1000px', width: '100%'}}>

				{/* tech used */}
				{/* progress */}

				<Box
					direction='column'
					justify='center'
					style={{ maxWidth: '1000px', width: '100%'}}
				>
					<Box
						background="neutral"
						alignSelf='center'
						direction='row'
						align='start'
						justify='between'
						style={{ maxWidth: '1000px', width: '100%'}}
					>
						<Box
							background="neutral"
							alignSelf='center'
							direction='row'
							justify='between'
							fill={true}
						>
							<Box
								animation={[{type: 'fadeIn'} ]}
								background="neutral"
								>
								<Heading size='45px' margin='0 0 10px 0'>Project 3/3</Heading>
								<Anchor size='medium' color='neutral-3' label="evthedev.com (this site!)" href="https://www.evthedev.com" />
							</Box>
							<Box
								animation={[{type: 'fadeIn', delay: 300 } ]}
								background="neutral"
								>
								<Heading size='45px' margin='0 0 10px 0'>What is it?</Heading>
								<Paragraph margin='0 0 20px'>A place to show off my work <Robot></Robot></Paragraph>
							</Box>

						</Box>

					</Box>
					<Box
						alignSelf='center'
						direction='column'
						align='start'
						justify='between'
						style={{ maxWidth: '1000px'}}
					>
						<Grid gap="small" columns={{ count: 3, size: 'small' }} responsive={true} style={{marginTop: '10px'}}>
							{ technologies.map((technology, index) => (
								<Card
									pad='0'
									gap='small'
									background='light-2'
									animation={[{type: 'fadeIn', delay: index*200 }, {type: 'slideDown', delay: index*200}]}
								>
									<CardBody pad="0 12px">
										<Box gap="small" align="start" direction="row" pad="small">
											<Box margin='16px 20px 0 0'>
												{technology.component}
											</Box>
											<Box>
												<Heading color={themeColors[Math.floor(Math.random() * themeColors.length)]} size='28px' margin='10px 0 0 0'>{technology.name}</Heading>
												<Paragraph color='dark-3'>{technology.description}</Paragraph>
											</Box>
										</Box>
									</CardBody>
								</Card>
							))}
						</Grid>
					</Box>
				</Box>

				<Box
					justify='between'
					direction='row'
					fill='horizontal'
					margin='80px 0 0 0'
				>			
						<Box>	
							<Link to='/showcase/advisr'>
								<Upgrade 
									size='80px'
									color='status-error'
									style={{ transform: 'rotate(-90deg)', cursor: 'pointer'}}
								/>
							</Link>
							<Paragraph color='dark-3'>Previous </Paragraph>
						</Box>	
						<Box>	
							<Inbox 
								size='80px' 
								style={{marginTop: '0', cursor: 'pointer'}}
								color='status-error'
									onClick={() => {window.open("mailto:hello@evthedev.com")}}
							/>
							<Paragraph color='dark-3'>Inbox me</Paragraph>
						</Box>
				</Box>



			</Box>
			
			

		</Box>

	)
}
EvTheDev.defaultProps = {
	align: 'center',
	pad: 'large',
  };
  