import * as React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Grommet, Paragraph } from 'grommet';
import { Apps, Gamepad } from 'grommet-icons';
import { Heading } from 'grommet';
// import RoutedButton from './RoutedButton'

import { useSelector, useDispatch } from "react-redux"
import { getPages, setActivePageId } from '../redux/pages/actions';
import { AppState } from '../redux/pages/interfaces';

export const Landing: React.FC = (props: any): JSX.Element => {

	const pages: any = useSelector((state: AppState) => state.pages);
	const dispatch = useDispatch();
	
	React.useEffect(() => {
      dispatch(getPages());
	dispatch(setActivePageId('1'))
      
	}, []);

  console.log('pages', pages)
  


	return (
		<Box
			fill='vertical'
			align='center'
			justify='center'
		>
			<div style={{marginTop: 'auto'}}>
				<Box
					animation={['fadeIn', 'slideDown']}
					background="neutral"
					align='center'
					>
					<Heading margin="none" size='130px'>Hello</Heading>
				</Box>
				<Box
					direction='row'
					justify='center'
				>
					<Box
						animation={[{type: 'fadeIn', delay: 1000 }, {type: 'slideDown'}]}
						background="neutral"
						alignSelf='center'
						>
						{/* <RoutedButton icon={<Apps />} path='/' hoverIndicator={true} /> */}
						<Heading margin="none" size='60px'>I am Ev</Heading>
					</Box>
					<Box
						animation={[{type: 'fadeIn', delay: 1500 } ]}
						background="neutral"
						alignSelf='center'
						>
						{/* <RoutedButton icon={<Apps />} path='/' hoverIndicator={true} /> */}
						<Heading margin="none" size='60px'>.</Heading>
					</Box>
					<Box
						animation={[{type: 'fadeIn', delay: 1700 } ]}
						background="neutral"
						alignSelf='center'
						>
						<Heading margin="none" size='60px'>.</Heading>
					</Box>
					<Box
						animation={[{type: 'fadeIn', delay: 1900 } ]}
						background="neutral"
						alignSelf='center'
						>
						<Heading margin="none" size='60px'>The Dev.</Heading>
					</Box>
				</Box>
			</div>
			<div style={{margin: 'auto 0 40px'}}>	
				<Link to='/main'>
					<Box animation={[{type: 'fadeIn', delay: 1900 }, {type: 'pulse'} ]}>	
						<Gamepad size='150px' color='status-error' />
					</Box>
				</Link>
				<Box
					animation={[{type: 'fadeIn', delay: 5000 } ]}
					height='auto'
				>	
					<Paragraph color='dark-3'>Go on, click it... </Paragraph>
				</Box>
			</div>
		</Box>

	)
}
Landing.defaultProps = {
	align: 'center',
	pad: 'large',
  };
  