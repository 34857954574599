import * as React from 'react';
import { Box, Paragraph, Button, Drop, Anchor, List, Grid, Card, CardBody, ResponsiveContext, Layer } from 'grommet';
import { DocumentPdf, Linkedin, Gremlin, Github, Upgrade, Reactjs, Code, Docker, Amazon, Secure, Wordpress, Action, Cubes } from 'grommet-icons';
import { Link } from 'react-router-dom';
import { Heading } from 'grommet';
import Prism from 'prismjs';
import OutsideClickHandler from 'react-outside-click-handler';

import { useSelector, useDispatch } from "react-redux"
import { getPages, setActivePageId } from '../../redux/pages/actions';
import { AppState } from '../../redux/pages/interfaces';
import { useRef, useContext, useEffect } from 'react';
import { themeColors } from '../../utilities/themeColors';
import { Header } from '../../partials/Header';

export const Shootsta: React.FC = (props: any): JSX.Element => {

	const pages: any = useSelector((state: AppState) => state.pages);
	const targetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const dispatch = useDispatch();
	
	const [isSocialMenuShown, setisSocialMenuShown] = React.useState<boolean>(false);
	let [currentStep, setCurrentStep] = React.useState<number>(1);

	const contributions = [
		'Successful completion and handover of project',
		'Styling enhancements',
	]
	const roles = [
		'Frontend Developer',
	]


	const technologies = [
		{
			name: 'ReactJs',
			component: <Reactjs size='50px'/>,
			description: 'User dashboard, video trimming and commenting interface,',
		},
		{
			name: 'Redux Saga',
			component: <Code size='50px'/>,
			description: 'State and side-effects management',
		},
		{
			name: 'React Drag and Drop',
			component: <Docker size='50px' />,
			description: 'Organisation and re-ordering of video clips',
		},
		{
			name: 'Bright cove',
			component: <Secure size='50px' />,
			description: 'Video editing API',
		},
	]
	
	useEffect(() => {
      	// dispatch(getPages());
		// dispatch(setActivePageId('4'))
	}, []);

	return (
		
		<Box
			fill={true}
			align='center'
			justify='between'
		>
			<Header />

			<Box
				style={{ maxWidth: '1000px', width: '100%'}}>

				{/* tech used */}
				{/* progress */}

				<Box
					direction='row'
					justify='center'
					style={{ maxWidth: '1000px', width: '100%'}}
				>
					{ currentStep === 1 && 
						<Box
							alignSelf='center'
							direction='row'
							align='start'
							justify='between'
							style={{ maxWidth: '1000px', width: '100%'}}
						>
							<Box
								alignSelf='center'
								direction='column'
								align='start'
								justify='around'
								margin='0 80px 0 0'
							>
								<Box
									animation={[{type: 'fadeIn'} ]}
									margin='0 0 40px 0'
									>
									<Heading size='45px' margin='10px 0 10px 0'>Project</Heading>
									<Anchor size='medium' color='neutral-3' label="Shootsta.com.au" href="https://Shootsta.com.au" />
								</Box>
								<Box
									animation={[{type: 'fadeIn', delay: 300 } ]}
									margin='0 0 40px 0'
									>
									<Heading size='45px' margin='10px 0 10px 0'>My roles</Heading>
									<List data={roles} />
								</Box>
								<Box
									animation={[{type: 'fadeIn', delay: 600 } ]}
									>
									<Heading size='45px' margin='10px 0 10px 0'>Contributions</Heading>
									<List  data={contributions} />
								</Box>

							</Box>

							<Box
								direction='column'
								align='start'
								justify='around'
							>
								<Box
									animation={[{type: 'fadeIn', delay: 900 } ]}
									>
									<Heading size='45px' margin='30px 0 10px 0'>What is it?</Heading>
									<Paragraph>Headquartered in Sydney, Shootsta is a custom video submission and editing platform web app, used by national video publishers. It was built with React and Redux while consuming a server-side API. The interface was featured-rich and was required to retain maximum functionality on mobile.</Paragraph>
									<Paragraph>The idea is to combine tools, technology, education and expertise to simplify the video production process, enabling people everywhere to make quality video content at scale, ready to share in just 24 hours.</Paragraph>
									<Paragraph>This project was successfully completed and handed over.</Paragraph>
								</Box>
							</Box>
						</Box>
					}
					{ currentStep === 2 && 
						<Box
							alignSelf='center'
							direction='column'
							align='start'
							justify='between'
							style={{ maxWidth: '1000px'}}
						>
							<Box
								alignSelf='center'
								direction='column'
								align='center'
								justify='between'
							>
								<Heading size='35px' margin='10px 0 40px 0'>Technologies used</Heading>

							</Box>
							<Grid gap="small" columns={{ count: 3, size: 'small' }} responsive={true}>
								{ technologies.map((technology, index) => (
									<Card
										pad='0'
										gap='small'
										background='light-2'
										animation={[{type: 'fadeIn', delay: index*200 }, {type: 'slideDown', delay: index*200}]}
									>
										<CardBody pad="0 12px">
											<Box gap="small" align="start" direction="row" pad="small">
												<Box margin='16px 20px 0 0'>
													{technology.component}
												</Box>
												<Box>
													<Heading color={themeColors[Math.floor(Math.random() * themeColors.length)]} size='28px' margin='10px 0 0 0'>{technology.name}</Heading>
													<Paragraph color='dark-3'>{technology.description}</Paragraph>
												</Box>
											</Box>
										</CardBody>
									</Card>
								))}
							</Grid>
						</Box>
					}
				</Box>

				<Box
					justify='between'
					direction='row'
					fill='horizontal'
					margin='80px 0 0 0'
				>			
					{ currentStep === 1 &&		
						<Box>	
							<Link to='/showcase/advisr'>
								<Upgrade 
									size='80px'
									color='status-error'
									style={{ transform: 'rotate(-90deg)', cursor: 'pointer'}}
								/>
							</Link>
							<Paragraph color='dark-3'>Advisr </Paragraph>
						</Box>
					}
					{ currentStep === 2 &&		
						<Box>	
							<Upgrade 
								size='80px'
								color='status-error'
								onClick={()=>setCurrentStep(1)}
								style={{ transform: 'rotate(-90deg)', cursor: 'pointer'}}
							/>
							<Paragraph color='dark-3'>Previous </Paragraph>
						</Box>
					}
					{ currentStep === 1 &&
						<Box>
							<Upgrade 
								size='80px'
								color='status-error'
								onClick={()=>setCurrentStep(2)}
								style={{ transform: 'rotate(90deg)', cursor: 'pointer'}}
							/>
							<Paragraph color='dark-3'>Technology</Paragraph>
						</Box>
					}		
					
					{ currentStep === 2 &&		
						<Box>	
							<Link to='/showcase/shootsta'>
								<Upgrade 
									size='80px'
									color='status-error'
									style={{ transform: 'rotate(90deg)', cursor: 'pointer'}}
								/>
							</Link>
							<Paragraph color='dark-3'>Investfit </Paragraph>
						</Box>
					}
				</Box>



			</Box>
			
			

		</Box>

	)
}
Shootsta.defaultProps = {
	align: 'center',
	pad: 'large',
  };
  