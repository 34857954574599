import React from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import { store } from './redux';
import { Landing } from './components/Landing';
import { Main } from './components/Main';
import { AppState } from './redux/pages/interfaces';
import { getPages, setActivePage, setActivePageId } from './redux/pages/actions';
import { Box, Grommet, Paragraph } from 'grommet';
import { Apps, Gamepad } from 'grommet-icons';
import { Advisr } from './components/showcase/Advisr';
import { Shootsta } from './components/showcase/Shootsta';
import { EvTheDev } from './components/showcase/EvTheDev';
import { Woodside } from './components/showcase/Woodside';

const App: React.FC = (props: any) : JSX.Element => {

	const pages: any = useSelector((state: AppState) => state.pages);
	const activePageId = useSelector((state: AppState) => state.activePageId);
	const dispatch = useDispatch();
	
		React.useEffect(() => {
      // dispatch(getPages());
      // dispatch(setActivePageId('1'))
      
	}, []);

  console.log('pages', pages)
  
	const theme = {
		global: {
		  font: {
			family: 'Montserrat',
			size: '18px',
      color: 'dark-1',
      weight: '400'
		  },
		},
		heading: {
			font: {
				family: 'Bungee Shade'
			}
    },
    button: {
      primary: {
        border: {
          
        }
      },
      border: {
        radius: '0',
        width: '3px'
      }
    },
    list: {
      item: {
        pad: {
          horizontal: 'small'
        }
      }
    }
  };
  
  return (

    
		<Grommet
      theme={theme}
      full={true}
      background='light-3'
    >
      <Box
        fill='vertical'
        align='center'
        justify='center'
      >
        <BrowserRouter>
          <Switch>
            <Route exact path='/' render={(props) => <Landing />} 
            />
          </Switch>
          <Switch>
            <Route exact path='/main' render={(props) => <Main />} 
            />
          </Switch>
          <Switch>
            <Route exact path='/showcase/advisr' render={(props) => <Advisr />} 
            />
          </Switch>
          <Switch>
            <Route exact path='/showcase/woodside' render={(props) => <Woodside />} 
            />
          </Switch>
          <Switch>
            <Route exact path='/showcase/shootsta' render={(props) => <Shootsta />} 
            />
          </Switch>
          <Switch>
            <Route exact path='/showcase/evthedev' render={(props) => <EvTheDev />} 
            />
          </Switch>
        </BrowserRouter>



      </Box>
    
    </Grommet>
  );
}

export default App;
