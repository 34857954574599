import * as React from 'react';
import { Box, Paragraph, Button, Drop, Anchor, List, Grid, Card, CardBody, ResponsiveContext, Layer } from 'grommet';
import { Share ,DocumentPdf, Linkedin, Gremlin, Github, Upgrade, Reactjs, Code, Docker, Amazon, Secure, Wordpress, Action, Cubes, Optimize, Shield, Test } from 'grommet-icons';
import { Link } from 'react-router-dom';
import { Heading } from 'grommet';
import Prism from 'prismjs';
import OutsideClickHandler from 'react-outside-click-handler';

import { useSelector, useDispatch } from "react-redux"
import { getPages, setActivePageId } from '../../redux/pages/actions';
import { AppState } from '../../redux/pages/interfaces';
import { useRef, useContext, useEffect } from 'react';
import { themeColors } from '../../utilities/themeColors';
import { Header } from '../../partials/Header';

export const Woodside: React.FC = (props: any): JSX.Element => {

	const pages: any = useSelector((state: AppState) => state.pages);
	const targetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const dispatch = useDispatch();
	
	const [isSocialMenuShown, setisSocialMenuShown] = React.useState<boolean>(false);
	let [currentStep, setCurrentStep] = React.useState<number>(1);

	const contributions = [
		'UI architecture',
		'App Productionisation',
		'ReactJs implementation',
		'Setting up of CI/CD on AWS',
		'Serverless implementation',
		'End-to-end testing',
		'System integrations'
	]
	const roles = [
		'Frontend-focused Full Stack Developer'
	]

	const technologies = [
		{
			name: 'ReactJs',
			component: <Reactjs size='50px'/>,
			description: 'Core product',
		},
		{
			name: 'AWS',
			component: <Amazon size='50px' />,
			description: 'Lambda, Cognito, CloudFormation, CodeBuild, CodePipeline and DynamoDb',
		},
		{
			name: 'NodeJs',
			component: <Code size='50px'/>,
			description: 'Backend',
		},
		{
			name: 'Typescript',
			component: <Shield size='50px' />,
			description: 'For typesafety',
		},
		{
			name: 'GraphQL & Rest API',
			component: <Secure size='50px' />,
			description: 'Microservices',
		},
		{
			name: 'Redux Saga',
			component: <Code size='50px'/>,
			description: 'State and side-effects management',
		},
		{
			name: 'Functional',
			component: <Optimize size='50px' />,
			description: '.. Programming, to keep components testable and small',
		},
		{
			name: 'Cypress, Jest, RTL',
			component: <Test size='50px' />,
			description: 'Unit testing & End-to-end testing',
		},
	]
	
	useEffect(() => {
      	dispatch(getPages());
		dispatch(setActivePageId('3'))
	}, []);

	return (
		
		<Box
			fill={true}
			align='center'
			justify='between'
		>
			<Header />
			
			<Box
				style={{ maxWidth: '1000px', width: '100%'}} fill={true} justify='center'>

				{/* tech used */}
				{/* progress */}

				<Box
					direction='row'
					justify='center'
					style={{ maxWidth: '1000px', width: '100%'}}
				>
					{ currentStep === 1 && 
						<Box
							background="neutral"
							alignSelf='center'
							direction='row'
							align='start'
							justify='between'
							style={{ maxWidth: '1000px', width: '100%'}}
						>
							<Box
								background="neutral"
								alignSelf='center'
								direction='column'
								align='start'
								justify='around'
								margin='0'
								basis='1/2'
								flex='grow'
							>
								<Box
									animation={[{type: 'fadeIn'} ]}
									background="neutral"
									>
									<Heading size='45px' margin='10px 0 10px 0'>Project 1/3</Heading>
									<Anchor size='medium' color='neutral-3' label="Fuse (Woodside)" href="https://www.woodside.com.au/media-centre/news-stories/story/dollars-and-sensors" target="_blank"/>
								</Box>
								<Box
									animation={[{type: 'fadeIn', delay: 300 } ]}
									background="neutral"
									>
									<Heading size='45px' margin='30px 0 10px 0'>What is it?</Heading>
									<Paragraph>Enterprise-scale web-based, virtual replica of a physical Western Australian natural gas facility, that gathers real world data from equipment and sensors.</Paragraph>
									<Paragraph><Anchor size='medium' color='neutral-3' label="See more" href="https://www.woodside.com.au/media-centre/news-stories/story/dollars-and-sensors" target="_blank"/> <Share size="15px"/></Paragraph>
								</Box>
								<Box
									animation={[{type: 'fadeIn', delay: 600 } ]}
									background="neutral"
									>
									<Heading size='45px' margin='10px 0 10px 0'>My roles</Heading>
									<List data={roles} />
								</Box>

							</Box>

							<Box
								background="neutral"
								direction='column'
								align='start'
								justify='around'
							>
								<Box
									animation={[{type: 'fadeIn', delay: 900 } ]}
									background="neutral"
									>
									<Heading size='45px' margin='10px 0 10px 0'>Contributions</Heading>
									<List  data={contributions} />
								</Box>
							</Box>
						</Box>
					}
					{ currentStep === 2 && 
						<Box
							background="neutral"
							alignSelf='center'
							direction='column'
							align='start'
							justify='between'
							style={{ maxWidth: '1000px'}}
						>
							<Box
								alignSelf='center'
								direction='column'
								align='center'
								justify='between'
							>
								<Heading size='35px' margin='10px 0 40px 0'>Technologies used</Heading>

							</Box>
							<Grid gap="small" columns={{ count: 3, size: 'small' }} responsive={true}>
								{ technologies.map((technology, index) => (
									<Card
										pad='0'
										gap='small'
										background='light-2'
										animation={[{type: 'fadeIn', delay: index*200 }, {type: 'slideDown', delay: index*200}]}
									>
										<CardBody pad="0 12px">
											<Box gap="small" align="start" direction="row" pad="small">
												<Box margin='16px 20px 0 0'>
													{technology.component}
												</Box>
												<Box>
													<Heading color={themeColors[Math.floor(Math.random() * themeColors.length)]} size='28px' margin='10px 0 0 0'>{technology.name}</Heading>
													<Paragraph color='dark-3'>{technology.description}</Paragraph>
												</Box>
											</Box>
										</CardBody>
									</Card>
								))}
							</Grid>
						</Box>
					}
				</Box>

				<Box
					justify='between'
					direction='row'
					fill='horizontal'
					margin='100px 0 0 0'
				>			
					{ currentStep === 1 &&		
						<Box>	
							<Link to='/main'>
								<Upgrade 
									size='80px'
									color='status-error'
									style={{ transform: 'rotate(-90deg)', cursor: 'pointer'}}
								/>
							</Link>
							<Paragraph color='dark-3'>Main </Paragraph>
						</Box>
					}
					{ currentStep === 2 &&		
						<Box>	
							<Upgrade 
								size='80px'
								color='status-error'
								onClick={()=>setCurrentStep(1)}
								style={{ transform: 'rotate(-90deg)', cursor: 'pointer'}}
							/>
							<Paragraph color='dark-3'>Previous </Paragraph>
						</Box>
					}
					{ currentStep === 1 &&
						<Box>
							<Upgrade 
								size='80px'
								color='status-error'
								onClick={()=>setCurrentStep(2)}
								style={{ transform: 'rotate(90deg)', cursor: 'pointer'}}
							/>
							<Paragraph color='dark-3'>Technology </Paragraph>
						</Box>
					}		
					
					{ currentStep === 2 &&		
						<Box>	
							<Link to='/showcase/advisr'>
								<Upgrade 
									size='80px'
									color='status-error'
									style={{ transform: 'rotate(90deg)', cursor: 'pointer'}}
								/>
							</Link>
							<Paragraph color='dark-3'>Advisr </Paragraph>
						</Box>
					}
				</Box>



			</Box>
			
			

		</Box>

	)
}
Woodside.defaultProps = {
	align: 'center',
	pad: 'large',
  };
  