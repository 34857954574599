import { AppState } from './pages/interfaces';
import thunk from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware } from 'redux';

// import initial state
import { initialState } from './pages/initialState';

// inmport reducers
import { pages } from './pages/reducers';

const rootReducer = combineReducers({
	pages
})

export const store = createStore(rootReducer, initialState as any, applyMiddleware(thunk));
