import { AppState } from './interfaces';
import { constants } from './constants';
import { initialState } from './initialState';

export const pages = (appState: AppState = initialState, action: any): AppState => {
	let { isFetching, activePageId, activePage, pages } = appState;
	const { IS_FETCHING, SET_ACTIVE_PAGE, SET_ACTIVE_PAGE_ID, SET_PAGES } = constants;
	
	switch (action.type) {
		case SET_ACTIVE_PAGE:
			activePage = action.payload;
			break;

		case SET_ACTIVE_PAGE_ID:
			activePageId = action.payload;
			break;

		case SET_PAGES:
			pages = action.payload;
			break;

		case IS_FETCHING:
			isFetching = action.payload;
			break;

		default:
			break;
	}

	return { isFetching, activePageId, activePage, pages };
}