import * as React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Drop, Avatar } from 'grommet';
import { DocumentPdf, Linkedin, Gremlin, Github, Inbox } from 'grommet-icons';
import OutsideClickHandler from 'react-outside-click-handler';

import { useRef } from 'react';
import { useDeviceDetect } from '../hooks/useDeviceDetect';

export const Header: React.FC = (props: any): JSX.Element => {
	const targetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const [isSocialMenuShown, setisSocialMenuShown] = React.useState<boolean>(false);
	const isMobile = useDeviceDetect();

	return (

			<Box
			fill='horizontal'
			justify='between'
			direction='row'
		>
			<Box
				animation={[{type: 'fadeIn', delay: 200 }, {type: 'slideDown'}]}
				margin='14px'
				style={{ minHeight: '78px' }}
			>
				{ isMobile &&
					<Link to="//drive.google.com/file/d/1aqT4gZ9mtp9yCY0cyEuA49YDNWXx9e7D/view?usp=sharing" target="_blank">
						<Button 
							primary
							a11yTitle="Hire"
							plain={false}
							label="Hire"
							color='status-error'
							size="large"
							icon={<DocumentPdf />}
						/>
					</Link>
				}
				{ !isMobile &&
					<Link to="//drive.google.com/file/d/1aqT4gZ9mtp9yCY0cyEuA49YDNWXx9e7D/view?usp=sharing" target="_blank">
						<Box round="full" overflow="hidden" background="neutral-1">
							<Button
								primary
								plain={false}
								color='status-error'
								size="large"
								icon={<DocumentPdf />}
							/>
						</Box>
					</Link>
					// <Link to="//drive.google.com/file/d/1aqT4gZ9mtp9yCY0cyEuA49YDNWXx9e7D/view?usp=sharing" target="_blank"><Button primary a11yTitle="Hire" plain={false} label="Hire" color='status-error' size="large" icon={<DocumentPdf />}/></Link>
				}

			</Box>
			<Box
				animation={[{type: 'fadeIn', delay: 500 }, {type: 'slideLeft'}]}
				margin='14px'
				ref={targetRef}
			>
				<Box round="full" overflow="hidden" background="neutral-1">
					<Avatar
						src="/avatar-ev.png"
						style={{ cursor: 'pointer'}} 
						onClick={() => setisSocialMenuShown(!isSocialMenuShown)}
					/>
				</Box>
				
				{ isSocialMenuShown &&

					// <OutsideClickHandler onOutsideClick={() => {
					// 	setisSocialMenuShown(false);
					// }}
					// >
						<Drop
							plain
							align={{ top: 'bottom', left: 'left' }}
							target={targetRef.current}
							style={{ alignItems: 'center' }}
						>
								<Linkedin size='30px' style={{marginTop: '15px', marginBottom: '15px', cursor: 'pointer'}} color='dark-2' onClick={() => {window.open("https://www.linkedin.com/in/ev-ooi")}} />
								<Github size='30px' style={{marginTop: '0', marginBottom: '15px', cursor: 'pointer'}} color='dark-2' onClick={() => {window.open("https://github.com/evthedev")}} />
								<Inbox size='30px' style={{marginTop: '0', marginBottom: '15px', cursor: 'pointer'}} color='dark-2' onClick={() => {window.open("mailto:hello@evthedev.com")}} />

						</Drop>
					// </OutsideClickHandler>
					}
			</Box>
		</Box>
	)
}