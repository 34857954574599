export const themeColors: string[] = [
	'brand',
	'accent-1',
	'accent-2',
	'accent-3',
	'accent-4',
	'neutral-1',
	'neutral-2',
	'neutral-3',
	'neutral-4',
	'status-critical',
	'status-error',
	'status-warning',
	'status-ok',
]