import { constants } from './constants';
import { Page } from './interfaces';

interface Action {
	type: string;
	payload: any;
}

const { IS_FETCHING, SET_ACTIVE_PAGE, SET_ACTIVE_PAGE_ID, SET_PAGES } = constants;

export const setIsFetching = (isFetching: boolean): Action => ({
	type: IS_FETCHING,
	payload: isFetching
})

export const setActivePage = (page: Page): Action => ({
	type: SET_ACTIVE_PAGE,
	payload: page
})

export const setActivePageId = (pageId: string): Action => ({
	type: SET_ACTIVE_PAGE_ID,
	payload: pageId
})

export const setPages = (pages: Page[]): Action => ({
	type: SET_PAGES,
	payload: pages
})

export const getPages = () => {
	return async (dispatch: any): Promise<void> => {
		dispatch(setIsFetching(true));
		const response = await fetch(
			`http://localhost:3000/pages`
		);

		const responseJson = await response.json();
		dispatch(setPages(responseJson));
		dispatch(setIsFetching(false));
	}
}

export const getPage = (pageId: string) => {
	return async (dispatch: any): Promise<void> => {
		dispatch(setIsFetching(true));
		const response = await fetch(
			`http://localhost:3000/pages/${pageId}`
		);

		const responseJson = await response.json();
		dispatch(setActivePage(responseJson));
		dispatch(setIsFetching(false));
	}
}