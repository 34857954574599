import * as React from 'react';
import { Box, Paragraph, Button, Drop } from 'grommet';
import { DocumentPdf, Linkedin, Gremlin, Github, Upgrade } from 'grommet-icons';
import { Link } from 'react-router-dom';
import { Heading } from 'grommet';
import OutsideClickHandler from 'react-outside-click-handler';

import { useSelector, useDispatch } from "react-redux"
import { getPages, setActivePageId } from '../redux/pages/actions';
import { AppState } from '../redux/pages/interfaces';
import { useRef } from 'react';
import { Header } from '../partials/Header';

export const Main: React.FC = (props: any): JSX.Element => {

	const pages: any = useSelector((state: AppState) => state.pages);
	const targetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const dispatch = useDispatch();
	
	const [isSocialMenuShown, setisSocialMenuShown] = React.useState<boolean>(false);
	let [currentStep, setCurrentStep] = React.useState<number>(1);
	
	React.useEffect(() => {
      	dispatch(getPages());
		dispatch(setActivePageId('2'))
      
	}, []);

	const increaseCurrentStep = () => {
		setCurrentStep(currentStep++)
	}

	return (
		
		<Box
			fill={true}
			align='center'
			justify='between'
		>
			<Header />
			<Box>

				{/* tech used */}
				{/* progress */}

				<Box
					direction='column'
					justify='center'
				>
					{ currentStep === 1 && 
						<Box
							background="neutral"
							alignSelf='center'
							direction='row-responsive'
							align='start'
							justify='around'
							fill='vertical'
						>
							<Box
								animation={[{type: 'fadeIn'} ]}
								background="neutral"
								fill='vertical'
								>
								<Paragraph margin="5px auto 0" >I build <Heading margin='10px 0 40px 0'>full<br/>stack<br/> web<br/> apps</Heading>(that look good)</Paragraph>
							</Box>
							<Box
								animation={[{type: 'fadeIn', delay: 500 } ]}
								background="neutral"
								alignContent='center'
								fill='vertical'
								>
								<Paragraph margin="5px auto" textAlign='center'>in <Heading margin='10px 0 50px 0'>JS<br/>TS<br/>PHP</Heading>mostly with <Heading margin='10px 0 0 0'>React, Node.js, Laravel</Heading></Paragraph>
							</Box>
							<Box
								animation={[{type: 'fadeIn', delay: 1000 } ]}
								background="neutral"
								alignContent='end'
								fill='vertical'
								>
								<Paragraph margin="5px auto" textAlign='end'>and deliver <Heading margin='10px 0 20px 0'>Archit-<br/>ecture</Heading><Heading margin='0 0 20px 0'>Cloud<br/>DevOps</Heading><Heading margin='10px 0 20px 0'>Integ-<br/>rations</Heading></Paragraph>
							</Box>

						</Box>
					}
				</Box>
			</Box>
			<Box
				justify='center'
			>
				<Link to='/showcase/woodside' style={{ textAlign: 'center'}} >
					<Upgrade 
						size='80px'
						color='status-error'
						style={{ transform: 'rotate(90deg)', cursor: 'pointer'}}
					/>
				</Link>
				<Paragraph color='dark-3'>My work </Paragraph>
			</Box>
			
			

		</Box>

	)
}
Main.defaultProps = {
	align: 'center',
	pad: 'large',
  };
  